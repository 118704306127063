body {
  background-color: #eee;
}

@media (prefers-color-scheme: dark) {
  body {
    color: #fff;
    background-color: #202123;
  }

  body nav {
    background-color: #26a69a;
  }

  body .card {
    background-color: #fff3;
  }

  body .btn {
    background-color: #ee6f73;
  }

  body .divider {
    opacity: .2;
  }

  body .sidenav {
    background-color: #2d2d31;
  }

  body .sidenav li a:not(.subheader) {
    color: #89b2f5;
  }

  body .sidenav li a:not(.subheader):hover {
    background-color: #3b4043;
  }

  body .sidenav li a.subheader, body .sidenav li a .material-icons {
    color: #9aa0a6;
  }

  body .collection {
    border: 1px solid #fff3;
  }

  body .collection .collection-item {
    background-color: #fff3;
    border-bottom: 1px solid #fff3;
  }

  body input {
    color: #fff;
  }
}
/*# sourceMappingURL=index.cee341bd.css.map */
